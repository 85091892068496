import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import i18n from '@/vue-app/plugins/i18n';
import { requiredRuleByKey } from '@/vue-app/utils/form-rules';

// Application
import { SearchTemplateQuestionnairesQuery }
  from '@/modules/my-investment/catalogs/allianz/template-questionnaire/application/queries/';
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  FilterDto,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/dtos/filter-dto';
import {
  TemplateQuestionnaireEntity,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/entities/template-questionnaire-entity';
import {
  OptionEntity,
} from '@/modules/my-investment/catalogs/allianz/option/domain/entities/option-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsProfilingDepositsAndWithdrawalsViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_QUESTIONNAIRE_TEMPLATE_QUERY)
  private readonly searchTemplateQuestionnairesQuery!: SearchTemplateQuestionnairesQuery;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly getPersonQueryService!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.profiling.deposits-and-withdrawals';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  filter_template: FilterDto = {
    tipoCuestionario: 'PERFIL_TRANSACCIONAL',
    versionCuestionario: '1.0.0',
  };

  small_screen: null | boolean = null;

  deposits_per_month: Array<OptionEntity> = [];

  deposits_amount_per_month: Array<OptionEntity> = [];

  withdrawals_per_month: Array<OptionEntity> = [];

  withdrawals_amount_per_month: Array<OptionEntity> = [];

  object_format = {
    llave: null,
    valor: null,
    descripcion: null,
  };

  inputs = {
    deposits_per_month: this.object_format,
    deposits_amount_per_month: this.object_format,
    withdrawals_per_month: this.object_format,
    withdrawals_amount_per_month: this.object_format,
  };

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'transactional_profile';

  investment_provider_id = '';

  is_loading = true;

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      estimated_deposit_amount: this.object_format,
      estimated_withdrawal_amount: this.object_format,
      estimated_deposit_transactions: this.object_format,
      estimated_withdrawal_transactions: this.object_format,
    },
  };

  rules = {
    deposits_per_month: [(value: string) => requiredRuleByKey(value, 'llave')],
    deposits_amount_per_month: [(value: string) => requiredRuleByKey(value, 'llave')],
    withdrawals_per_month: [(value: string) => requiredRuleByKey(value, 'llave')],
    withdrawals_amount_per_month: [(value: string) => requiredRuleByKey(value, 'llave')],
  };

  form_validity = false;

  get templateQuestionnaires() {
    return this.searchTemplateQuestionnairesQuery.execute(this.filter_template);
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get userName() {
    return this.getPersonQueryService.execute().name;
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  setItems(templateQuestionnaires: Array<TemplateQuestionnaireEntity>) {
    const { secciones } = templateQuestionnaires[0];
    this.deposits_per_month = secciones[0].preguntas[0].opciones;
    this.deposits_amount_per_month = secciones[0].preguntas[1].opciones;
    this.withdrawals_per_month = secciones[1].preguntas[0].opciones;
    this.withdrawals_amount_per_month = secciones[1].preguntas[1].opciones;
  }

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.inputs.deposits_per_month = this.on_boarding_step.payload.estimated_deposit_transactions;
    this.inputs.deposits_amount_per_month = this.on_boarding_step.payload.estimated_deposit_amount;
    this.inputs.withdrawals_per_month = this
      .on_boarding_step.payload.estimated_withdrawal_transactions;
    this.inputs.withdrawals_amount_per_month = this
      .on_boarding_step.payload.estimated_withdrawal_amount;
  }

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      // eslint-disable-next-line max-len
      this.on_boarding_step.payload.estimated_deposit_amount = this.inputs.deposits_amount_per_month;
      // eslint-disable-next-line max-len
      this.on_boarding_step.payload.estimated_withdrawal_amount = this.inputs.withdrawals_amount_per_month;
      this.on_boarding_step.payload.estimated_deposit_transactions = this.inputs.deposits_per_month;
      // eslint-disable-next-line max-len
      this.on_boarding_step.payload.estimated_withdrawal_transactions = this.inputs.withdrawals_per_month;
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    await this.verifyStep(steps);

    const template_questionnaires = await this.templateQuestionnaires;
    this.setItems(template_questionnaires);

    this.is_loading = false;
  }
}
